import swal from 'sweetalert'
import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'
import moment from 'moment'

export function getSubdomain(hostname) {
  var regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$')
  var urlParts = regexParse.exec(hostname)
  if (!urlParts) return ''
  return hostname.replace(urlParts[0], '').slice(0, -1)
}

function getDomain() {
  return window.location.hostname
    .split('.')
    .slice(-2)
    .join('.')
}
const knownHosts = [
  // 'brickspms.com',
  'brickspms.click',
  'staging.brickspms.com',
  'web-dev.brickspms.click',
  'staging.brickspms.click',
  'web-staging.brickspms.click',
  'newdev.brickspms.com',
  'localhost',
]

export function isCompanyInDomain() {
  const subdomain = window.location.hostname
  if (knownHosts.includes(subdomain)) return false
  return subdomain.split('.')[0]
}

export function getEnv() {
  const subdomain = getSubdomain(window.location.hostname)
  const subDomains = subdomain.split('.')

  if (window.location.hostname === 'brickspms.click') return 'production'
  // if (window.location.hostname === 'brickspms.click') return 'productionnew'

  if (subDomains.includes('staging')) return 'staging'

  if (subDomains.includes('web-dev')) return 'dev'

  if (subDomains.includes('newdev')) return 'newdev'

  if (subDomains.includes('web-staging')) return 'newstaging'

  if (window.location.hostname === 'localhost') return 'localhost'

  return 'production'

  //return 'localhost'
  // if(subDomains.length >= 2){
  //   return subDomains[1]
  // }else if(subDomains.length === 1){
  //   return 'localhost'
  // }else{
  //   return subDomains[0]
  // }
}

export function toFixed(num, fixed = 2) {
  const number = isNaN(num) ? 0 : num
  const result = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  return number.toString().match(result)[0]
}

export function toFixed2(num, fixed = 2) {
  const number = isNaN(num) ? 0 : num
  const result = (
    Math.floor(number * Math.pow(10, fixed)) / Math.pow(10, fixed)
  ).toFixed(fixed)
  return result
}

export function numberFormat(number, locale = 'en-US', config) {
  return new Intl.NumberFormat(locale, { ...config }).format(number)
}

export function dateFormatter(date) {
  return {
    format: function(formateString = 'DD MMM YYYY') {
      if (!date) return date
      return moment(date).format(formateString)
    },
  }
}

export function handleFiltering(callback, key, value, valueKey) {
  const { filters } = this.state
  let v
  if (Array.isArray(key)) {
    key.map((k, i) => {
      v = valueKey && value[i] ? value[i][valueKey] : v
      _.set(filters, k, v)
    })
  } else {
    v = valueKey && value ? value[valueKey] : value
    _.set(filters, key, v)
  }

  filters.page = key === 'page' ? value : 1

  this.setState({ filters })
  callback(filters)
}

export function notFoundData(data) {
  const { fetching, loading } = this.props
  if (fetching || loading) return false

  if (data.length <= 0) return true

  return false
}

export function handleLoadingStatus(data) {
  const { fetching } = this.props

  if (data.length > 0 && fetching) return 'updating'

  if (data.length <= 0 && fetching) return 'fetching'
}

export function handleDelete(url, dispatch, label = {}, config = {}) {
  const axiosConfig = {
    url: url,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'cache-control': 'reload',
      'Auth-Token': localStorage.getItem('id_token'),
      slug: localStorage.getItem('company'),
    },
    ...config,
  }

  const labels = {
    title: 'Are you sure ?',
    confirmBtn: 'Yes, Delete!',
    success: 'Delete Successfully!',
    error: 'Error!',
    cancel: 'Cancel',
    ...label,
  }

  swal({
    title: labels.title,
    text: labels.text,
    icon: 'warning',
    buttons: {
      cancel: labels.cancel,
      catch: {
        text: labels.confirmBtn,
        closeModal: false,
      },
    },
  })
    .then(name => {
      if (!name) throw null

      return axios(axiosConfig)
    })
    .then(result => {
      swal({
        title: labels.success,
        text: labels.successResponseMessage || result.data.message || '...',
        icon: 'success',
        button: false,
        timer: 2000,
      }).then(() => {
        if (dispatch) dispatch()
      })
    })
    .catch(error => {
      if (error) {
        const err = error.response ? error.response.data.error : error
        swal({
          title: labels.error,
          text: err,
          icon: 'error',
        })
      } else {
        swal.stopLoading()
        swal.close()
      }
    })
}

export function confirm(url, dispatch, label = {}, config = {}) {
  const axiosConfig = {
    url: url,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'cache-control': 'reload',
      'Auth-Token': localStorage.getItem('id_token'),
      slug: localStorage.getItem('company'),
    },
    ...config,
  }

  const labels = {
    title: 'Are you sure ?',
    confirmBtn: 'Yes, Delete!',
    success: 'Delete Successfully!',
    error: 'Error!',
    cancel: 'Cancel',
    successMsg: '...',
    ...label,
  }

  swal({
    title: labels.title,
    icon: 'warning',
    buttons: {
      cancel: labels.cancel,
      catch: {
        text: labels.confirmBtn,
        closeModal: false,
      },
    },
  })
    .then(name => {
      if (!name) throw null

      return axios(axiosConfig)
    })
    .then(result => {
      swal({
        title: labels.success,
        text: labels.successMsg,
        icon: 'success',
        button: false,
        timer: 2000,
      }).then(() => {
        if (dispatch) dispatch()
      })
    })
    .catch(error => {
      if (error) {
        const err = error.response ? error.response.data.error : error
        swal({
          title: labels.error,
          text: err,
          icon: 'error',
        })
      } else {
        swal.stopLoading()
        swal.close()
      }
    })
}

export function donwloadFile(
  url,
  fileName = 'file.pdf',
  onFinished = () => {},
  par,
) {
  const params = removeNullValues(par)
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
    params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false })
    },
    headers: {
      'cache-control': 'reload',
      'Auth-Token': localStorage.getItem('id_token'),
      slug: localStorage.getItem('company'),
    },
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName) //or any other extension
      document.body.appendChild(link)
      link.click()
      onFinished()
      //fileDownload(response.data, 'credit_note.pdf')
    })
    .catch(error => {
      // alert('Something wrong on download the file!')
      onFinished()
    })
}

export function getUrlVars() {
  var vars = {}
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value,
  ) {
    vars[key] = value
  })
  return vars
}

export function getUrlParam(parameter, defaultvalue = '') {
  var urlparameter = defaultvalue
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter]
  }
  return urlparameter
}

export function canAccess(module, permission, redirect = '/') {
  const { permissions } = this.props
  if (
    permissions &&
    !permissions.is_admin &&
    !permissions[module][permission]
  ) {
    this.props.router.push(redirect)
    return false
  }
  return true
}

export function permissionCanAccess(module, permission, permissions) {
  if (
      permissions &&
      !permissions.is_admin &&
      !permissions[module][permission]
  ) {
    return false
  }
  return true
}

export function toObjectKey(str, from, to) {
  const removeDash = str.split(' ').join('_')
  return removeDash.toLowerCase()
}

export function yearsList(min) {
  let year = new Date().getFullYear()
  const to = year - min
  const results = []
  //results.push(<option value="">All Years</option>)
  for (; year >= to; year--) {
    results.push({ id: year, name: year })
  }

  return results
}

export function setToValue(obj, prop, value) {
  if (typeof prop === 'string') prop = prop.split('.')

  if (prop.length > 1) {
    var e = prop.shift()
    setToValue(
      (obj[e] =
        Object.prototype.toString.call(obj[e]) === '[object Object]'
          ? obj[e]
          : {}),
      prop,
      value,
    )
  } else obj[prop[0]] = value
}

export function removeNullValues(obj) {
  let result = {}

  if (!obj) return false

  Object.keys(obj).map(key => {
    if (!obj[key]) return false

    if (typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
      result[key] = removeNullValues(obj[key])
      return false
    }

    result[key] = obj[key]
  })
  return result
}

export function removeNullValuesAndAll(obj) {
  let result = {}

  if (!obj) return false

  Object.keys(obj).map(key => {
    if (!obj[key]) return false
    if (obj[key] === 'all') return false

    if (typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
      result[key] = removeNullValuesAndAll(obj[key])
      return false
    }

    result[key] = obj[key]
  })
  return result
}

export function deebValue(obj, path) {
  var paths = path.split('.'),
    current = obj,
    i

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return undefined
    } else {
      current = current[paths[i]]
    }
  }
  return current
}


export function toPascalCase(string) {
  if (!string){
    return ''
  }

  return `${string}`
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, 'g'), ' ')
      .replace(new RegExp(/[^\w\s]/, 'g'), '')
      .replace(
          new RegExp(/\s+(.)(\w*)/, 'g'),
          ($1, $2, $3) => `${$2.toUpperCase() + $3}`
      )
      .replace(new RegExp(/\w/), s => s.toUpperCase());
}
